<template>
	<div>
		<div v-if="isVisible" class="full-overlay">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isVisible: Boolean
	},
}
</script>

<style scoped>

.full-overlay{
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.6);

	display: flex;
	box-orient: vertical;
	box-pack: center;
}
</style>
