var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog" }, [
    _c("h1", [_vm._v(_vm._s(_vm.header))]),
    _vm._v(" "),
    _c("p", { staticClass: "queue-progress-message", class: _vm.className }, [
      _c("img", { attrs: { src: _vm.image, alt: "" } }),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } })
    ]),
    _vm._v(" "),
    _vm.isDownloadReady
      ? _c("p", [
          _vm._v("\n      Video is ready. \n      "),
          _c(
            "button",
            {
              class: _vm.isDownloadClicked ? "grey-button" : "green-button",
              on: { click: _vm.downloadFile }
            },
            [_vm._v("\n        Download\n      ")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticStyle: { "text-align": "center" } }, [
      _c(
        "button",
        {
          class: _vm.isSafeToLeave ? "green-button" : "grey-button",
          attrs: { href: "#" },
          on: { click: _vm.newVideo }
        },
        [_vm._v("\n\t\t\t\tNEW VIDEO\n\t\t\t")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }