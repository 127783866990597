import "core-js/stable/array/for-each"
import "core-js/stable/array/filter"
import "core-js/stable/array/includes"
import "core-js/stable/array/iterator"

import Vue from "vue"
import TovidioRoot from 'components/TovidioRoot'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import googleApiLoaded from 'App/googleApiLoaded';

Sentry.init({
  dsn: 'https://27af659c8fe74b66b166a4ac1ec9d4f9@sentry.io/1830630',
  integrations: [new Integrations.Vue({
    Vue,
    attachProps: true,
    logErrors: true, // TODO: remove in prod
  })],
});


const vueapp = new Vue({
  el: '#vueapp',
  render: h => h(TovidioRoot)
});

// window.vueapp = vueapp;
window.googleApiLoaded = googleApiLoaded;
