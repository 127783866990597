import { render, staticRenderFns } from "./TovidioPanel.vue?vue&type=template&id=a611a354&scoped=true&"
import script from "./TovidioPanel.vue?vue&type=script&lang=js&"
export * from "./TovidioPanel.vue?vue&type=script&lang=js&"
import style0 from "./TovidioPanel.vue?vue&type=style&index=0&id=a611a354&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a611a354",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a611a354')) {
      api.createRecord('a611a354', component.options)
    } else {
      api.reload('a611a354', component.options)
    }
    module.hot.accept("./TovidioPanel.vue?vue&type=template&id=a611a354&scoped=true&", function () {
      api.rerender('a611a354', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "lumen/src/components/TovidioPanel.vue"
export default component.exports