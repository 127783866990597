var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dialog" }, [
      _c("h1", [_vm._v("Waiting for YouTube authentication")]),
      _vm._v(" "),
      _c("h3", [_vm._v("Do not close this window!")]),
      _vm._v(" "),
      _c("img", { attrs: { src: "/assets/img/progress-bar.gif", alt: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }