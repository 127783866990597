module.exports = () => {
  gapi.load('auth2', function() {

    const scopes = [
      'https://www.googleapis.com/auth/youtube.upload',
      'https://www.googleapis.com/auth/youtube.readonly',
    ];

    auth2 = gapi.auth2.init({
      client_id: GOOGLE_API_CLIENT_ID, // substituted by WebPack plugin
      scope: scopes.join(' '), // Scopes to request in addition to 'profile' and 'email'
    });
  });
}
