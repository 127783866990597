var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog" }, [
    _c("h1", [_vm._v("Authentication failed")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "action" }, [
      _c(
        "button",
        {
          staticClass: "green-button",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              return _vm.$emit("retry-auth")
            }
          }
        },
        [_vm._v("Retry")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v("Tovidio still needs your permission"),
      _c("br"),
      _vm._v("to upload video to YouTube.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }