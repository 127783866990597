/**
 * Image preview pan and zoom in the browser.
 * Desktop: mouse click-and-drag to pan, mouse wheel to zoom
 * Mobile: tap and drag to pan, two-finder pinch to zoom
 * 
 * By Sergei Sokolov hello@sergeisokolov.com Telegram @sergiks
 * Moscow, Russia, 
 * on an unusually warm for November 7th 2019, rainy day.
 */

class DraggableElement {

  constructor(element) {
    this.el = element;
    this.el.style.transformOrigin = '0 0';

    this.containerRect = this.el.parentElement.getBoundingClientRect();

    const rect = this.el.getBoundingClientRect();
    this.width = rect.width; 
    this.height = rect.height;

    // mode: cover
    this.scaleMin = Math.max(this.containerRect.width / this.width, this.containerRect.height / this.height);
    this.scaleMax = Math.max(2 * this.scaleMin, Math.min(this.width / 1280, this.height / 720));
    this.scale = this.scaleMin;

    // Selected crop parameters in source image resolution
    this.crop = {x:0, y:0, width: this.width, height: this.height};

    this.isDragging = false;
    
    this.zero = {x: rect.x, y: rect.y}; // correction
    this.pos = {x:0, y:0};   // translated top left
    this.start = {x:0, y:0}; // mouse click pos before dragging

    console.log("Zero", this.zero);

    // Touch interface
    this.evCache = [];
    this.prevDistance = -1;

    // bind this
    ['updateEl', 'onDragStart', 'onDragMove', 'onDragStop', 'onWheel',
      'onPointerDown', 'onPointerMove', 'onPointerUp', 'drawX', 'updateScaleXY'
    ]
      .forEach(prop => this[prop] = this[prop].bind(this))
    ;

    const listen = (eventName, methodName, opts = {}) => 
      this.el.addEventListener(eventName, this[methodName], opts)
    ;

    // Event listeners
    [
      ['mousedown',  'onDragStart'],
      ['mouseup',    'onDragStop'],
      ['mouseleave', 'onDragStop'],
      ['mousemove',  'onDragMove', {passive: true}],
      ['wheel',      'onWheel'],
      ['pointerdown'  , 'onPointerDown'],
      ['pointermove'  , 'onPointerMove'],
      ['pointerup'    , 'onPointerUp'],
      ['pointercancel', 'onPointerUp'],
      ['pointerout'   , 'onPointerUp'],
      ['pointerleave' , 'onPointerUp'],
    ].forEach(params => listen(...params))

    this.updateEl();
  }


  updateEl() {
    
    if (this.pos.x > 0) this.pos.x = 0;
    if (this.pos.y > 0) this.pos.y = 0;

    const curWidth = this.scale * this.width
    if (this.pos.x + curWidth < this.containerRect.width) this.pos.x = this.containerRect.width - curWidth;

    const curHeight = this.scale * this.height
    if (this.pos.y + curHeight < this.containerRect.height) this.pos.y = this.containerRect.height - curHeight;

    const transform = [
      `translate(${this.pos.x}px, ${this.pos.y}px)`,
    	`scale(${this.scale})`,
    ].join(' ');

    this.el.style.transform = transform;

    // Update crop params
    this.crop.x = Math.round(-this.pos.x / this.scale);
    this.crop.y = Math.round(-this.pos.y / this.scale);
    this.crop.width = Math.round(this.containerRect.width / this.scale);
    this.crop.height = Math.round(this.containerRect.height / this.scale);

    this.crop.top = this.crop.y;
    this.crop.bottom = this.crop.y + this.crop.height;
    this.crop.left = this.crop.x;
    this.crop.right = this.crop.x + this.crop.width;
  }

  onDragStart(event) {
    if (event.preventDefault) event.preventDefault();
    
	  this.start.x = event.offsetX;
	  this.start.y = event.offsetY;
    
	  this.isDragging = true;
	}
  
  
	onDragMove(event) {
    if (!this.isDragging) return;
    
	  this.pos.x += event.offsetX - this.start.x;
    this.pos.y += event.offsetY - this.start.y;

    this.updateEl();
	}
  
  
  onDragStop(event) {
    this.isDragging = false;
  }


  onWheel(event) {
    event.preventDefault();
    this.updateScaleXY(event.deltaY / -100, event.clientX, event.clientY);
    this.updateEl();
  }
  
  
  onPointerDown(event) {
    this.evCache.push(event);
  }


  onPointerMove(event) {
    event.preventDefault();

    const i = this.evCache.findIndex(e => e.pointerId === event.pointerId);
    if (!~i) return;

    const prevEvent = this.evCache[i];
    this.evCache[i] = event;

    if (this.evCache.length === 2) { // two-finger pinch scalin'

      const dx = this.evCache[1].clientX - this.evCache[0].clientX;
      const dy = this.evCache[1].clientY - this.evCache[0].clientY;

      const midX = this.evCache[0].clientX + dx / 2;
      const midY = this.evCache[0].clientY + dy / 2;

      const curDistance = Math.hypot(dx, dy);
      const prevDistance = this.prevDistance;

      this.prevDistance = curDistance;

      if (prevDistance <= 0) return;

      this.updateScaleXY((curDistance - prevDistance) / 100, midX, midY);

    } else if (this.evCache.length === 1) { // one-finger drag

      this.prevDistance = -1;

      this.pos.x += event.clientX - prevEvent.clientX;
      this.pos.y += event.clientY - prevEvent.clientY;
    }
    
    this.updateEl();
  }


  onPointerUp(event) {
    const i = this.evCache.find(e => e.pointerId === event.pointerId);
    if (!!~i) this.evCache.splice(i, 1);

    // If the number of pointers down is less than two then reset diff tracker
    if (this.evCache.length < 2) this.prevDistance = -1;
  }


  updateScaleXY(dScale, x, y) {

    this.scale *= (1 + dScale);

    if (this.scale < this.scaleMin) {
      this.scale = this.scaleMin;
      return;
    } else if (this.scale > this.scaleMax) {
      this.scale = this.scaleMax;
      return;
    }
    
    const Cx = x - this.zero.x;
    const Cy = y - this.zero.y;

    // this.drawX(Cx, Cy);

    this.pos.x += dScale * (this.pos.x - Cx);
    this.pos.y += dScale * (this.pos.y - Cy);
  }


  drawX(x, y) {
    x = Math.round(x);
    y = Math.round(y);

    if (!this._xline  ||  !this._yline) this.el.opacity = '0.8';

    if (!this._xline) {
      let div = document.querySelector('div.xline');
      if (!div) {
        div = document.createElement('div');
        div.classList.add('xline');
        div.setAttribute('style',
        `height:${this.height+10}px; width:1px;top:-5px;background-color:#333;position:absolute;`
        );
        div = this.el.parentNode.insertBefore(div, this.el);
      }
      this._xline = div;
    }
    
    if (!this._yline) {
      let div = document.querySelector('div.yline');
      if (!div) {
        div = document.createElement('div');
        div.classList.add('yline');
        div.setAttribute('style',
          `width:${this.width+10}px; height:1px;left:-5px;background-color:#333;position:absolute;`
        );
        div = this.el.parentNode.insertBefore(div, this.el);
      }
      this._yline = div;
    }

    this._xline.style.left = `${x}px`;
    this._yline.style.top = `${y}px`;
  }  
}

export default DraggableElement;
