export default [
  {"id":"1","snippet":{"title":"Film & Animation","assignable":true}},
  {"id":"2","snippet":{"title":"Autos & Vehicles","assignable":true}},
  {"id":"10","snippet":{"title":"Music","assignable":true}},
  {"id":"15","snippet":{"title":"Pets & Animals","assignable":true}},
  {"id":"17","snippet":{"title":"Sports","assignable":true}},
  {"id":"19","snippet":{"title":"Travel & Events","assignable":true}},
  {"id":"20","snippet":{"title":"Gaming","assignable":true}},
  {"id":"22","snippet":{"title":"People & Blogs","assignable":true}},
  {"id":"23","snippet":{"title":"Comedy","assignable":true}},
  {"id":"24","snippet":{"title":"Entertainment","assignable":true}},
  {"id":"25","snippet":{"title":"News & Politics","assignable":true}},
  {"id":"26","snippet":{"title":"Howto & Style","assignable":true}},
  {"id":"27","snippet":{"title":"Education","assignable":true}},
  {"id":"28","snippet":{"title":"Science & Technology","assignable":true}}
];
