var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "six columns relative panel-audio" }, [
    _c(
      "div",
      { staticClass: "step s2" },
      [
        _vm.isFileUploaded
          ? [
              _c("span", { staticClass: "badge-done" }, [_vm._v("2")]),
              _vm._v(" "),
              _c("span", { staticClass: "text-done" }, [_vm._v("mp3 uploaded")])
            ]
          : [
              _c("span", { staticClass: "badge" }, [_vm._v("2")]),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [_vm._v("upload your mp3")])
            ]
      ],
      2
    ),
    _vm._v(" "),
    _vm.isFileSelected
      ? _c("div", { staticClass: "preview-panel" }, [
          _c("h3", [_vm._v(_vm._s(_vm.filename))]),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.duration))])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isFileSelected && !_vm.isFileUploaded
      ? _c("div", { staticClass: "progress" }, [
          _c("div", {
            staticClass: "progress-bar",
            style: _vm.progressBarStyle
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isFileSelected
      ? _c(
          "div",
          {
            staticClass: "panel-size",
            class: _vm.isFileValid && "dropzone",
            on: {
              drop: _vm.onDrop,
              dragenter: _vm.onDragOver,
              dragover: _vm.onDragOver,
              dragleave: _vm.onDragLeave,
              dragexit: _vm.onDragLeave
            }
          },
          [
            _c("h3", [_vm._v("Drop mp3 here")]),
            _vm._v(" "),
            _c("span", [_vm._v("or")]),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "green-button", on: { click: _vm.onClickBrowse } },
              [_vm._v("BROWSE")]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.errorMessage.length > 0
      ? _c("div", {
          staticClass: "error-message",
          domProps: { innerHTML: _vm._s(_vm.errorMessage) },
          on: {
            click: function($event) {
              _vm.errorMessage = ""
            }
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }