<template>
  <div class="dialog">
    <h1>Final step: enter details of the video</h1>

    <label for="title">Title the video:</label>
    <input
      v-model="title"
      type="text"
      maxlength="100"
      id="title"
      ref="inputtitle"
    >

    <label>Pick a category:
    <select v-model="category">
      <option
        v-for="(title, code) in categories"
        :value="code"
        :key="code"
      >{{ title }}</option>
    </select></label>

    <label>Describe it:<textarea v-model="description" id="description"></textarea></label>

    <label>Add some tags:<input type="text" v-model="tags" id="tags"></label>

    <label>Video privacy:
    <select v-model="privacy">
      <option value="public">Public</option>
      <option value="private">Private</option>
      <option value="unlisted">Unlisted</option>
    </select></label>

    <div style="text-align:center">
      <a
        :class="actionButtonClass"
        @click="onClickPublish"
      >
        PUBLISH VIDEO
      </a>
    </div>

    <div v-if="isPublishing" class="publish-progress">
      <img src="/assets/img/progress-bar.gif">
      <p>Wait for the server to generate &amp; upload your video.</p>
    </div>
  </div>
</template>

<script>
import defaultVideoCategories from 'App/videoCategories'

export default {
  data() {
    return {
      title: '',
      category: '10',
      categories: {
        '0': 'Please select',
        '1': "Film & Animation",
        '10': "Music",
        '22': "People & Blogs",
      },
      description: '',
      tags: '',
      privacy: 'public',

      isPublishing: false,

      id: '',
    }
  },

  methods: {
    onClickPublish() {
      if (!this.isValidInfoFilled) return;

      this.isPublishing = true;

      const data = {
        title: this.title,
        category: this.category,
        description: this.description,
        tags: this.tags,
        privacy: this.privacy,
      };

      console.log("TovidioOverlayDetails emit:", data);

      this.$emit('publish-video', data);
    },
  },

  computed: {
    isValidInfoFilled() {
      return this.title.length  &&  this.description.length;
    },

    actionButtonClass() {
      const classes = ['publish-button'];

      if (this.isValidInfoFilled) {
        classes.push('red-button');
      } else {
        classes.push('grey-button');
      }

      return classes.join(' ');
    }
  },

  created() {
    defaultVideoCategories.forEach(
      cat => this.categories[cat.id] = cat.snippet.title
    );
  },

  mounted() {
    this.id = this._uid;
    this.$nextTick(() => this.$refs.inputtitle.focus());
  },
}
</script>

<style scoped>
.dialog{
	margin: auto;
	background: #fff;
	border-radius: 3px;
	border: 1px solid #000;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.6);
	width: 390px;
	padding: 10px 10px 15px;
}

	h1 {
		font-size: 20px;
		line-height: 20px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ccc;
		margin: 10px 0;
	}

	h3 {
		font-size: 18px;
		line-height: 23px;
	}

	img {
		margin: auto;
		display: block;
		margin-top: 15px;
		margin-bottom: 10px;
	}

textarea {
	width: 360px;
}

.publish-button {
	margin-left: 10px;
	cursor: pointer;
}

.publish-progress {
	text-align: center;	
	border-top: 1px solid #ccc;
	padding-top: 0px;
}

.publish-progress p {
	margin-bottom: 0px;
}

</style>
