var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog" }, [
    _c("h1", [_vm._v("Which YouTube channel to publish to?")]),
    _vm._v(" "),
    _c("p", [
      _c(
        "button",
        {
          staticClass: "green-button",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("collect-details")
            }
          }
        },
        [_vm._v("\n      Same as previous video\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "green-button",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("retry-auth")
            }
          }
        },
        [_vm._v("\n      Another user / another channel\n    ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }