<template>
  <tovidio-panel 
    :session_uuid="session_uuid"
    :key="session_uuid"
    :isYouTubeConnected="isYouTubeConnected"
    :isFirstVisit="isFirstVisit"
    @reset="getSessionUuid"
    @youtube-connected="onYouTubeConnected"
  />
</template>


<script>
import TovidioPanel from 'components/TovidioPanel'
import axios from 'axios'
import ls from 'local-storage'

const USER_UUID = 'user_uuid'; // LocalStorage key holding session uuid

export default {
  data() {
    return {
      session_uuid: '',
      user_uuid: '',
      isYouTubeConnected: false,
      isFirstVisit: false,
    }
  },

  components: {
    TovidioPanel,
  },

  methods: {
    onYouTubeConnected() {
      this.isYouTubeConnected = true;
      console.log("YouTube connected!");
    },

    getSessionUuid() {
      axios.post('/api/uuid')
      .then(
        res => {
          if (!res.data) {
            console.error("Getting UUID failed: no response data", res);
            return;
          }
          
          if (res.data.session_uuid) {
            this.session_uuid = res.data.session_uuid;
            axios.defaults.headers.common['TOVIDIO-SESSION-UUID'] = this.session_uuid;
          }

          if (res.data.user_uuid) {
            if (this.user_uuid) {
              if (this.user_uuid === res.data.user_uuid) {
                // all OK
              } else {
                // TODO: user_uuid updated – Why?
              }
            } else {
              this.user_uuid = res.data.user_uuid;
              axios.defaults.headers.common['TOVIDIO-USER-UUID'] = this.user_uuid;
              ls(USER_UUID, this.user_uuid); // save to LocalStorage
            }
          }

          if (res.data.isYouTubeConnected) {
            this.isYouTubeConnected = true;
          }

        },
        err => console.error(err)
      );
    },
  },

  created() {
    // check if LS has user id key
    const user_uuid = ls(USER_UUID);
    if (user_uuid) {
      this.user_uuid = user_uuid;
      this.isFirstVisit = true;
      axios.defaults.headers.common['TOVIDIO-USER-UUID'] = user_uuid;
    }

    this.getSessionUuid();
  },
}
</script>
