<template>
	<div class="panel-bg shift-by-two">
		<panel-image
			ref="panelImage"
			@file-selected="isImageSelected=true"
			@file-uploaded="onImageUploaded"
		/>

		<panel-audio
			ref="panelAudio"
			@file-selected="isAudioSelected=true"
			@file-uploaded="onAudioUploaded"
		/>

		<div class="twelve columns connect-section">
			<p>
				<input
					v-model="isTermsAccepted"
					type="checkbox"
					:id="id"
				>
				<label
					:for="id"
				>
					I agree to the
					<a href="/privacy/" target="PrivacyPolicy">Privacy Policy</a>
					and the
					<a href="/terms/" target="TermsConditions">Terms and Conditions</a>
				</label>
			</p>
			<span :class="isYouTubeConnected ? 'badge-done' : 'badge'">3</span>

			<template v-if="!isYouTubeConnected">
				<a
					:class="getActionButtonClass"
					@click.stop.prevent="onClickConnectYouTube"
					title="Connect to YouTube"
					href="#auth"
					target="YouTubeAuth"
				>
					CONNECT
				</a>
				<span style="margin-left:10px">to</span>
				<img
					src="/assets/img/yt_logo_rgb_light.png"
					alt="YouTube"
					style="width: 126px;margin: 10px 12px 0px 12px;position: relative;top: 9px;"
				>
			</template>
			<template v-else>
				<a
					:class="getActionButtonClass"
					@click.stop.prevent="onClickCreateVideo"
					title="Create video on YouTube"
					href="#create"
				>
					CREATE VIDEO
				</a>
				<span style="margin-left:10px">on</span>
				<img
					src="/assets/img/yt_logo_rgb_light.png"
					alt="YouTube"
					style="width: 126px;margin: 10px 12px 0px 12px;position: relative;top: 9px;"
				>
			</template>
			or
			<a
				@click.stop.prevent="onClickRenderDownload"
				:class="getDownloadButtonClass"
				style="margin-left: 10px;"
				href="#download"
			>
				download
			</a>

		</div>

		<TovidioOverlay
			:isVisible="!!overlayComponent"
		>
			<component
				:is="overlayComponent"
				@publish-video="onPublishVideo"
				@retry-auth="onClickConnectYouTube"
				@reset="$emit('reset')"
				@collect-details="onCollectDetails"
			/>
		</TovidioOverlay>
	</div>
</template>


<script>
import axios from 'axios'

import PanelImage from './PanelImage'
import PanelAudio from './PanelAudio'

import TovidioOverlay from './TovidioOverlay'
import TovidioOverlayChannels from './TovidioOverlayChannels'
import TovidioOverlayDetails from './TovidioOverlayDetails'
import TovidioOverlayPublishing from './TovidioOverlayPublishing'
import TovidioOverlayDownload from './TovidioOverlayDownload'
import TovidioOverlayWaiting from './TovidioOverlayWaiting'
import TovidioOverlayAuthFailure from './TovidioOverlayAuthFailure'

import defaultVideoCategories from 'App/videoCategories'


let OAuthWindow = null; // window reference

export default {
  components: {
    PanelImage,
    PanelAudio,
		TovidioOverlay,
		TovidioOverlayDetails,
		TovidioOverlayPublishing,
		TovidioOverlayWaiting,
		TovidioOverlayAuthFailure,
  },

	props: {
		session_uuid: String,
		googleUserId: String,
		isYouTubeConnected: Boolean,
		isFirstVisit: Boolean,
	},

	data() {
		return {
			isImageSelected: false,
			isAudioSelected: false,

			isImageUploaded: false,
			isAudioUploaded: false,
			isTermsAccepted: this.isFirstVisit || this.isYouTubeConnected,

			id: null,

			overlayComponent: null,

			channel_id: '',

			crop: null,
		}
	},

	mounted() {
		this.id = this._uid;

		// testing: display last popup 
		//setTimeout(() => {this.overlayComponent = TovidioOverlayChannels}, 100);
	},

	computed: {
		isYouTubeButtonEnabled() {
			return this.isImageSelected && this.isAudioSelected && this.isTermsAccepted;
		},

		isDownloadButtonEnabled() {
			return this.isImageUploaded && this.isAudioUploaded && this.isTermsAccepted;
		},

		getActionButtonClass() {
			if (this.isImageSelected && this.isAudioSelected && this.isTermsAccepted) {
				return 'green-button';
			} else {
				return 'grey-button';
			}
		},

		getDownloadButtonClass() {
			if (this.isImageUploaded && this.isAudioUploaded && this.isTermsAccepted) {
				return '';
			} else {
				return 'link-inactive';
			}
		}
	},
	

	methods: {
		// Open YT connect dialog, then ask for video info.
		// Meanwhile large mp3 and image can keep uploading.
		onClickConnectYouTube() {
			if (!this.isYouTubeButtonEnabled) return;

			gtag('event', 'connect', {"target": "YouTube"});

			// Display "Waiting" overlay
			this.overlayComponent = TovidioOverlayWaiting;

			auth2.grantOfflineAccess()
			    .then((authResult) => {
						if (!authResult['code']) {

							// error
							throw "No code from Google";
						}

						const code = authResult['code'];

						console.log('Auth completed!', code);

						// Send the code to the server
						const data = {
							code: code,
							language: window.navigator.language,
						};

						const headers = {
							'X-Requested-With': 'XMLHttpRequest',
							'Content-Type': 'application/json',
						};

						return axios.post('/api/onetimecode', data, {headers: headers});
					})
					.then((response) => {
						console.log("Here we get result of our server side auth", response);

						// TODO: process and update app state

						if (response.data.error) {
							console.error(response.data.error);
							this.overlayComponent = TovidioOverlayAuthFailure;
							return;
						}

						if (response.data.categories) {
							defaultVideoCategories.splice(0, defaultVideoCategories.length, ...response.data.categories);
						}
						
						if (response.data.result !== 'OK') {
							console.error('Status is not OK', response.data);
						}
						
						this.overlayComponent = TovidioOverlayDetails;
					})
					.catch(err => {
						
						// Closed auth window
						gtag('event', 'connect-failed', {"reason": err});
						console.error('GAuth error caught', err);

						this.overlayComponent = TovidioOverlayAuthFailure;
					})
			;
			
		},



		onMessageFromOAuth(event) {
			console.log("onMessageFromOAuth: Message event:", event.data);

			if (event.origin !== APP_URL) return;


			if (OAuthWindow) {
				if (!OAuthWindow.closed) OAuthWindow.close();
				OAuthWindow = null;
			}


			if (event.data === 'error') { // access denied by user

				this.overlayComponent = TovidioOverlayAuthFailure;
				gtag('event', 'connect-failed', {"reason": "declined"});

			} else {

				if (event.data.session_uuid !== this.session_uuid) { // wrong auth ???
					this.overlayComponent = TovidioOverlayAuthFailure; // TODO: special component for this case
					gtag('event', 'connect-failed', {"reason": "wrong window uuid"});
					return;
				}

				this.$emit('youtube-connected');
				this.overlayComponent = TovidioOverlayDetails;

				gtag('event', 'connected');

				if (event.data.googleUserId) {
					this.$emit('got-google-user-id', event.data.googleUserId);
				}
				
				if (event.data.hasRefreshToken) this.$emit('got-refresh-token');
			}
		},


		// Already authenticated from previous clip
		onClickCreateVideo() {
			this.overlayComponent = TovidioOverlayChannels;
		},

		onCollectDetails() {
			this.overlayComponent = TovidioOverlayDetails;
		},

		onImageUploaded() {
			this.isImageUploaded = true;
			this.$emit('image-uploaded');
		},

		onAudioUploaded() {
			this.isAudioUploaded = true;
			this.$emit('audio-uploaded');
		},
		
		// on event from OverlayDetails
		onPublishVideo(data) {

			const crop = this.$refs.panelImage.getCropRectangle();
			data.crop = crop;

			console.log("TovidioPanel onPublishVideo", data);

			// send publishing data to server
			const pDetails = axios.put('/api/details', data)
			.then(
				res => console.log("Video details put OK", res),
				err => console.error(err),
			);

			gtag('event', 'render', {"target": "YouTube"});


			const pImage = this.isImageUploaded ? Promise.resolve()
				: new Promise(res => this.$on('image-uploaded', res));

			const pAudio = this.isAudioUploaded ? Promise.resolve()
				: new Promise(res => this.$on('audio-uploaded', res));

			Promise.all([pDetails, pImage, pAudio])
			.then(
				res => {
					this.overlayComponent = TovidioOverlayPublishing;
				},
				err => console.error(err) // TODO: error display component
			);

			if (!this.isImageUploaded) {
				// TODO display progress message in TovidioOverlayDetails bottom
			}

			if (!this.isAudioUploaded) {
				// TODO display progress message in TovidioOverlayDetails bottom
			}

		},


		// Enqueue job to only render, than offer download link
		onClickRenderDownload() {
			if (!this.isDownloadButtonEnabled) return;

			gtag('event', 'render', {"target": "download"});

			const pImage = 
					this.isImageUploaded 
							? Promise.resolve()
							: new Promise(res => this.$on('image-uploaded', res));

			const pAudio =
					this.isAudioUploaded
							? Promise.resolve()
							: new Promise(res => this.$on('audio-uploaded', res));

			const crop = this.$refs.panelImage.getCropRectangle();


			Promise.all([pImage, pAudio])
			.then(
				res => axios.put('/api/renderos', crop),
				err => console.error(err),
			)
			.then(
				res => {
					this.overlayComponent = TovidioOverlayDownload;
				},
				err => console.error(err) // TODO: error display component
			);
		},


		getVideoCategories() {
			jsonp(
				'https://www.googleapis.com/youtube/v3/videoCategories?part=snippet&regionCode=ID&key=' + GOOGLE_API_BROWSER_KEY,
				null,
				(err, data) => {

					if (err) console.error(err);
					if (data && data.error) console.error(data.error);

					const valid = [];
					
					if (data && data.items) {
						valid.push(...data.items.filter(item => item.snippet.assignable === true));
					}

					if (valid.length === 0) {
						console.log("Valid categories list is empty, assigning default");
						valid = defaultVideoCategories;
					}

					const elCategory = document.getElementById('category');
					valid.forEach(category => {
						const option = document.createElement('option');
						option.innerText = category.snippet.title;
						option.value = category.id;
						if (category.id == 10) option.setAttribute('selected', 'selected');
						elCategory.appendChild(option);
					});
				}
			);
		}
	}
}
</script>


<style scoped>
.panel-bg {
	border-radius: 20px;
	background: white;
	float: left;
	padding-top: 60px;
	border-bottom: 3px solid #8f8f8f;
	padding-bottom: 15px;
	position: relative;
}

.youtube-logo {
	width: 126px;
	margin: 0 0 0 12px;
	position: relative;
	top: 9px;
}


.connect-section{
	text-align: center;
	margin-top: 49px;
	position: relative;
	border-top: 1px dashed #ccc;
	padding-top: 16px;
	clear: left;
}


.connect-section h4 {
	margin-top: 30px;
}

.badge, .badge-done {
  border-radius: 20px;
  font-size: 20px;
  line-height: 41px;
  text-align: center;
  color: #fff;
  height: 40px;
  padding: 0px 15px;
  display: inline-block;
  margin-right: 10px;
}
.badge { background-color: #09f }
.badge-done { background-color: #07b81c }

.link-inactive {
	color:#BDBDBD;
	text-decoration: none;
}
</style>
