<template>
  <div class="six columns relative panel-audio">
    <div class="step s2">
      <template v-if="isFileUploaded">
        <span class="badge-done">2</span>
        <span class="text-done">mp3 uploaded</span>
      </template>
      <template v-else>
        <span class="badge">2</span>
        <span class="text">upload your mp3</span>
      </template>
    </div>

    <div v-if="isFileSelected" class="preview-panel">
      <h3>{{ filename }}</h3>
      <h3>{{ duration }}</h3>
    </div>

    <div v-if="isFileSelected  &&  !isFileUploaded" class="progress">
      <div class="progress-bar" :style="progressBarStyle"></div>
    </div>

    <div v-if="!isFileSelected" 
      @drop      = "onDrop"
      @dragenter = "onDragOver"
      @dragover  = "onDragOver"
      @dragleave = "onDragLeave"
      @dragexit  = "onDragLeave"
      class      = "panel-size"
      :class = "isFileValid && 'dropzone'"
    >
      <h3>Drop mp3 here</h3>
      <span>or</span>
      <a class="green-button" @click="onClickBrowse">BROWSE</a>
    </div>

    <div
      v-if="errorMessage.length > 0"
      v-html="errorMessage"
      @click="errorMessage = ''"
      class="error-message"
    />
  </div>




</template>

<script>
import axios from 'axios'

export default {
  props: {
    uuid: Number
  },

  data() {
    return {
      typesAccepted: 'audio/mpeg,audio/mp3'.split(','),
      isFileValid: false,    // when dragged over
      // isFileSelected: false, // got File variable
      isFileUploaded: false, // upload to server confirmed
      progress: 0,
      filename: '',
      duration: '',
      errorMessage: '',
    }
  },
  
  computed: {
    isFileSelected() {
      return !!this.filename.length;
    },

    progressBarStyle() {
      return `width: ${Math.round(100 * this.progress)}%;`
    },
  },

  methods: {

    reset() {
      this.isFileValid = false;
      this.isFileUploaded = false;
      this.progress = 0;
      this.filename = '';
      this.duration = '';
    },

    onClickBrowse() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = this.typesAccepted.join(',');
      input.addEventListener('change', this.onFileSelected);
      input.click();
    },


    onDrop(event) {
      event.preventDefault();

      if (event.dataTransfer.items) {

        const items = [...event.dataTransfer.items]
          .filter(item => item.kind === 'file')
        ;

        if (!items.length) return;
        
        const file = items[0].getAsFile();
        const ext = file.name.split('.').pop();
        // console.log("Items file", file.name, ext);

        this.handleFile( file );

      } else if (event.dataTransfer.files  &&  event.dataTransfer.files.length) {

        const file = event.dataTransfer.files[0];
        // console.log("Files file", file.name);

        this.handleFile( file );

      } else {
        console.error('DragDrop not supported');
        console.log('Drop event:', event);
      }
    },


    onDragOver(event) {
      if (!event.dataTransfer.types.includes('Files')) {
        this.isFileValid = false;
        return;
      }

      event.preventDefault();
      this.isFileValid = true;
    },

    onDragLeave(event) {
      this.isFileValid = false;
    },

    onFileSelected(event) {
      if (!event || !event.target || !event.target.files || !event.target.files.length) return;
      this.handleFile( event.target.files[0] );
    },

    handleFile(file) {
      let error;
      if (file.type  &&  !this.typesAccepted.includes(file.type)) {
        error = `Invalid file type ${file.type}<br />Expected: audio/mp3`;
      }
      if (file.size > 5e8) {
        error = `File is too big: ${Math.round(file.size/1048576)} Mb.<br />Not more than 500 Mb is expected.`;
      }
      if (error) {
        this.errorMessage = error;
        this.reset();
        return;
      }

      this.filename = file.name;
      this.errorMessage = '';
      this.$emit('file-selected');


      // Upload the file
      axios.post('/upload/audio', file, {
        onUploadProgress: (event) => {
          this.progress = event.loaded / event.total; // 0..1
        },
      }).then (
        res => {
          console.log("Audio uploaded", res);
          if (res.data  &&  res.data.result  && res.data.result === 'success') {
            this.isFileUploaded = true;
            this.$emit('file-uploaded');
            if (res.data.duration) {
              this.duration = `${Math.floor(res.data.duration)}s`
            }

          } else {
            this.isFileUploaded = false;
          }
        },

        err => {
          console.error(err);
          this.isFileUploaded = false;
        }
      );
    },
  },
}
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .panel-audio {
    margin-top: 104px;
  }
}


.green-button {
	margin-left: 117px;
	margin-top: 30px;
}
.relative { position:relative }

.panel-size {
  background: #eeeeee;
  height: 190px;
  position: relative;
}

.panel-size h3 {
  text-align: center;
  width: 100%;
  padding-top: 50px;
  max-height: 34px;
  overflow: hidden;
}

.panel-size span {
  margin: auto;
  position: absolute;
  left: 164px;
}
.panel-size span::after {
  content: '';
  position: absolute;
  left: 20px;
  top: 10px;
  height: 1px;
  width: 100px;
  background: #ccc;
}
.panel-size span::before {
  content: '';
  position: absolute;
  right: 20px;
  top: 10px;
  height: 1px;
  width: 100px;
  background: #ccc;
}




.dropzone{
  background: #ccffcc !important;
}


.preview-panel {
  width: 340px;
  height: 191px;
  overflow: hidden;
  /* cursor: move; */
  margin-bottom: 5px;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 10;
  border: 1px solid #CCC;
}

.step {
  position: absolute;
  left: 0px;
  top: -50px;
}

.badge, .badge-done {
  border-radius: 20px;
  font-size: 20px;
  line-height: 41px;
  text-align: center;
  color: #fff;
  height: 40px;
  padding: 0px 15px;
  display: inline-block;
  margin-right: 10px;
}
.badge { background-color: #09f }
.badge-done { background-color: #07b81c }

.text, .text-done {
  font-size: 20px;
}
.text { color: #09F }
.text-done { color: #07b81c !important }

h3 {
  text-align: center;
  width: 100%;
  padding-top: 50px;
  max-height: 34px;
  overflow: hidden;
}

  /***************** PROGRESS BAR ****************/

.progress {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  z-index: 20;
}

.progress-bar {
  width: 0%;
  height: 16px;
  border-radius: 4px;
  background-color: #169DDD;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
}


div.error-message {
	position: absolute;
	height: 40px;
	top: 193px;
	width: 340px;
	overflow: hidden;
	color: red;
  cursor: pointer;
}

</style>
