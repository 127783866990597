<template>
  <div class="six columns relative no-margin-right">
    <div class="step s1">
      <template v-if="isFileUploaded">
        <span class="badge-done">1</span>
        <span class="text-done">image uploaded</span>
      </template>
      <template v-else>
        <span class="badge">1</span>
        <span class="text">upload an image</span>
      </template>
    </div>

    <div v-if="isFileSelected" class="preview-panel">
      <img
        :src="previewSource"
        @load="onPreviewLoaded"
        ref="previewImage"
        alt=""
      />
    </div>

    <div v-if="isFileSelected  &&  !isFileUploaded" class="progress">
      <div class="progress-bar" :style="progressBarStyle"></div>
    </div>

    <div
      v-if="!isFileSelected"
      @drop      = "onDrop"
      @dragenter = "onDragOver"
      @dragover  = "onDragOver"
      @dragleave = "onDragLeave"
      @dragexit  = "onDragLeave"
      class      = "panel-size"
      :class = "isFileValid && 'dropzone'"
    >
      <h3>Drop image here</h3>
      <span>or</span>
      <a class="green-button" @click="onClickBrowse">BROWSE</a>
    </div>

    <div
      v-if="errorMessage.length > 0"
      v-html="errorMessage"
      @click="errorMessage = ''"
      class="error-message"
    />
  </div>
</template>

<script>
import DraggableElement from 'DraggableElement'
import axios from 'axios'

export default {

  data() {
    return {
      typesAccepted: 'image/jpeg,image/gif,image/png'.split(','),
      myCroppa: {},
      previewSource: '',
      draggable: null,
      isFileValid: false,
      isFileUploaded: false,
      progress: 0,
      filename: '',
      errorMessage: '',
    }
  },

  computed: {
    isFileSelected() {
      return this.previewSource.length > 0;
    },

    cropRectangle() {
      if (!this.draggable) return;

      return this.draggable.crop;
    },

    progressBarStyle() {
      return `width: ${Math.round(100 * this.progress)}%;`
    },

  },

  methods: {
    reset() {
      this.isFileValid = false;
      this.isFileUploaded = false;
      this.progress = 0;
      this.filename = '';
      this.previewSource = '';
    },

    onClickBrowse() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = this.typesAccepted.join(',');
      input.addEventListener('change', this.onFileSelected);
      input.click();
    },


    onDrop(event) {
      event.preventDefault();

      if (event.dataTransfer.items) {

        [...event.dataTransfer.items]
          .filter(item => item.kind === 'file')
          .forEach(item => {
            const file = item.getAsFile();
            const ext = file.name.split('.').pop();
            console.log("Items file", file.name, ext);

            this.handleFile( file );

          });

      } else if (event.dataTransfer.files) {

        [...event.dataTransfer.files].forEach(file => {
          console.log("Files file", file.name);
          this.handleFile( file );
        });

      } else {

        console.error('DragDrop not supported');
        console.log('Drop event:', event);

      }

    },


    onDragOver(event) {
      if (!event.dataTransfer.types.includes('Files')) {
        this.isFileValid = false;
        return;
      }

      event.preventDefault();
      this.isFileValid = true;
    },


    onDragLeave(event) {
      this.isFileValid = false;
    },


    onFileSelected(event) {
      if (!event || !event.target || !event.target.files || !event.target.files.length) return;
      this.handleFile( event.target.files[0] );
    },


    handleFile(file) {

      let error;
      if (file.type  &&  !this.typesAccepted.includes(file.type)) {
        error = `Invalid file type: ${file.type}.<br/>Expected image/jpeg, image/png or image/gif`;
      }
      if (file.size > 2e7) error = `File is too big: ${Math.round(file.size/1048576)} Mb. Limit is 20 Mb`;
      if (error) {
        this.errorMessage = error;
        this.reset();
        return;
      }

      this.filename = file.name;
      this.errorMessage = '';
      this.$emit('file-selected');

      // Upload the file
      axios.post('/upload/image', file, {
        onUploadProgress: (event) => {
          this.progress = event.loaded / event.total; // 0..1
          console.log("progress", event)
        },
      }).then (
        res => {
          console.log("Image uploaded", res);
          if (res.data  &&  res.data.result  && res.data.result === 'success') {
            this.isFileUploaded = true;
            this.$emit('file-uploaded');

          } else {
            this.isFileUploaded = false;
            if (res.data  &&  res.data.error) {
              this.errorMessage = res.data.error;
            }
            this.reset();
          }
        },

        err => {
          console.error(err);
          this.isFileUploaded = false;
        }
      );

      
      // Show preview image
      const reader  = new FileReader();
      reader.addEventListener("load", () => {
        this.previewSource = reader.result; // dataUrl
      }, false);

      reader.readAsDataURL(file);
    },

    onPreviewLoaded() {
      this.draggable = new DraggableElement( this.$refs.previewImage );
    },

    getCropRectangle() {
      return this.cropRectangle;
    }
  },
}
</script>


<style scoped>
.no-margin-right {margin-right:0}

.dropzone{
  background: #ccffcc !important;
}

.green-button {
	margin-left: 117px;
	margin-top: 30px;
}
.relative { position:relative }

.panel-size {
  background: #eeeeee;
  height: 190px;
  position: relative;
}

.panel-size h3 {
  text-align: center;
  width: 100%;
  padding-top: 50px;
  max-height: 34px;
  overflow: hidden;
}

.panel-size span {
  margin: auto;
  position: absolute;
  left: 164px;
}
.panel-size span::after {
  content: '';
  position: absolute;
  left: 20px;
  top: 10px;
  height: 1px;
  width: 100px;
  background: #ccc;
}
.panel-size span::before {
  content: '';
  position: absolute;
  right: 20px;
  top: 10px;
  height: 1px;
  width: 100px;
  background: #ccc;
}


.preview-panel {
  width: 340px;
  height: 191px;
  overflow: hidden;
  cursor: move;
  margin-bottom: 5px;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 10;
  border: 1px solid #CCC;
  touch-action: none;
}

.step{
  position: absolute;
  left: 0px;
  top: -50px;
}

.badge, .badge-done {
  border-radius: 20px;
  font-size: 20px;
  line-height: 41px;
  text-align: center;
  color: #fff;
  height: 40px;
  padding: 0px 15px;
  display: inline-block;
  margin-right: 10px;
}
.badge { background-color: #09f }
.badge-done { background-color: #07b81c }

.text, .text-done {
  font-size: 20px;
}
.text { color: #09F }
.text-done { color: #07b81c !important }


/***************** PROGRESS BAR ****************/

.progress {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  z-index: 20;
}

.progress-bar {
  width: 0%;
  height: 16px;
  border-radius: 4px;
  background-color: #169DDD;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
}


div.error-message {
	position: absolute;
	height: 40px;
	top: 193px;
	width: 340px;
	overflow: hidden;
	color: red;
  cursor: pointer;
}

</style>
