var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog" }, [
    _c("h1", [_vm._v(_vm._s(_vm.header))]),
    _vm._v(" "),
    _c("p", { staticClass: "queue-progress-message", class: _vm.className }, [
      _c("img", { attrs: { src: _vm.image, alt: "" } }),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } })
    ]),
    _vm._v(" "),
    _c("p", [
      _vm.isSafeToLeave
        ? _c("span", [
            _vm._v("\n\t\t\t\tIt is now safe to leave this page. \n\t\t\t\t"),
            _c("br"),
            _vm._v("You can close this window or "),
            _c("a", { attrs: { href: "#" }, on: { click: _vm.newVideo } }, [
              _vm._v("create")
            ]),
            _vm._v(" a new video.\n\t\t\t\t"),
            _c("br")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        { attrs: { href: "https://studio.youtube.com/", target: "_blank" } },
        [_vm._v("Manage")]
      ),
      _vm._v(" Your YouTube channel.\n\t\t")
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticStyle: { "text-align": "center" } }, [
      _c(
        "button",
        {
          class: _vm.isSafeToLeave ? "green-button" : "grey-button",
          attrs: { href: "#" },
          on: { click: _vm.newVideo }
        },
        [_vm._v("\n\t\t\t\tNEW VIDEO\n\t\t\t")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }