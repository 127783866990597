var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog" }, [
    _c("h1", [_vm._v("Final step: enter details of the video")]),
    _vm._v(" "),
    _c("label", { attrs: { for: "title" } }, [_vm._v("Title the video:")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.title,
          expression: "title"
        }
      ],
      ref: "inputtitle",
      attrs: { type: "text", maxlength: "100", id: "title" },
      domProps: { value: _vm.title },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.title = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("label", [
      _vm._v("Pick a category:\n  "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.category,
              expression: "category"
            }
          ],
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.category = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.categories, function(title, code) {
          return _c("option", { key: code, domProps: { value: code } }, [
            _vm._v(_vm._s(title))
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("label", [
      _vm._v("Describe it:"),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.description,
            expression: "description"
          }
        ],
        attrs: { id: "description" },
        domProps: { value: _vm.description },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.description = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("label", [
      _vm._v("Add some tags:"),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.tags,
            expression: "tags"
          }
        ],
        attrs: { type: "text", id: "tags" },
        domProps: { value: _vm.tags },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.tags = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("label", [
      _vm._v("Video privacy:\n  "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.privacy,
              expression: "privacy"
            }
          ],
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.privacy = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c("option", { attrs: { value: "public" } }, [_vm._v("Public")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "private" } }, [_vm._v("Private")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "unlisted" } }, [_vm._v("Unlisted")])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { "text-align": "center" } }, [
      _c(
        "a",
        { class: _vm.actionButtonClass, on: { click: _vm.onClickPublish } },
        [_vm._v("\n      PUBLISH VIDEO\n    ")]
      )
    ]),
    _vm._v(" "),
    _vm.isPublishing
      ? _c("div", { staticClass: "publish-progress" }, [
          _c("img", { attrs: { src: "/assets/img/progress-bar.gif" } }),
          _vm._v(" "),
          _c("p", [
            _vm._v("Wait for the server to generate & upload your video.")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }