var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tovidio-panel", {
    key: _vm.session_uuid,
    attrs: {
      session_uuid: _vm.session_uuid,
      isYouTubeConnected: _vm.isYouTubeConnected,
      isFirstVisit: _vm.isFirstVisit
    },
    on: {
      reset: _vm.getSessionUuid,
      "youtube-connected": _vm.onYouTubeConnected
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }