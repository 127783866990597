<template>
  <div class="dialog">
		<h1>{{ header }}</h1>
		<p
			class="queue-progress-message"
			v-bind:class="className"
		>
			<img :src="image" alt="">
			<span v-html="message"></span>
		</p>

		<p>
			<span v-if="isSafeToLeave">
				It is now safe to leave this page. 
				<br />You can close this window or <a href="#" @click="newVideo">create</a> a new video.
				<br />
			</span>
			<a href="https://studio.youtube.com/" target="_blank">Manage</a> Your YouTube channel.
		</p>
		<hr>
		<div style="text-align:center">
			<button
				@click="newVideo"
				:class="isSafeToLeave ? 'green-button':'grey-button'"
				href="#"
			>
				NEW VIDEO
			</button>
		</div>
  </div>
</template>

<script>
import axios from 'axios'


const messages = {
	'queued': {
		header: 'Processing...',
		message: 'Video is waiting in a queue. %QUEUE% videos ahead.',
		isDone: false,
		className: 'inprogress',
		image: '/assets/img/icon-cog.svg',
	},
	'rendering': {
		header: 'Processing Your video',
		message: 'Video is rendering..',
		isDone: false,
		className: 'inprogress',
		image: '/assets/img/icon-cog.svg',
	},
	'rendered': {
		header: 'Processing Your video',
		message: 'Your video is rendered.',
		isDone: false,
		className: 'inprogress',
		image: '/assets/img/icon-cog.svg',
	},
	'uploading': {
		header: 'Processing Your video',
		message: 'Uploading to YouTube.',
		isDone: false,
		className: 'inprogress',
		image: '/assets/img/icon-cog.svg',
	},
	'uploaded': {
		header: 'Processing complete',
		message: 'Your <a href="https://www.youtube.com/watch?v=%VIDEO_ID%" target="_blank">video</a> is published!',
		isDone: true,
		className: 'ok',
		image: '/assets/img/icon-thumbs-up.svg',
	},
	'failed': {
		header: 'Processing failed',
		message: 'Blah. An error occured',
		isDone: true,
		className: 'error',
		image: '/assets/img/icon-warning.svg',
	},
	'downloadable': {
		header: 'Processing complete',
		message: '<a href="%DOWNLOAD_URL%" target="_blank">Download</a> your video',
		isDone: true,
		className: 'ok',
		image: '/assets/img/icon-thumbs-up.svg',
	}
};


export default {
	data() {
		return {
			header: 'Processing your video...',
			message: 'Adding video to queue...',
			image: '/assets/img/icon-cog.svg',
			className: 'inprogress',
			isDone: false,
			status: '',
			queued: -1,
			isSafeToLeave: false,
			timeout: null,
		}
	},

	methods: {

		setUpdate(data) {
			if (!data  ||  !data.status) {
				console.error('No status in response');
				return;
			}

			const status = data.status;
			if (!messages.hasOwnProperty(status)) {
				console.error('No text for status', status);
				return;
			}
			this.isSafeToLeave = true;

			const state = messages[status];

			this.header    = state.header;
			this.message   = state.message;
			if (data.hasOwnProperty('video_id')) this.message = state.message.replace('%VIDEO_ID%', data.video_id);
			if (data.hasOwnProperty('download_url')) this.message = state.message.replace('%DOWNLOAD_URL%', data.download_url);
			if (status === 'queued') this.message = state.message.replace('%QUEUE%', data.queue || 0);
			this.isDone    = state.isDone;
			this.image     = state.image;
			this.className = state.className;
			this.isDone    = state.isDone;

			this.queued = data.hasOwnProperty('queued') ? data.queued : -1;

			if (!state.isDone) this.timeout = setTimeout(this.getUpdate, 1000 + Math.floor(Math.random() * 1000));
		},

		getUpdate() {
			axios.get('/api/status')
			.then(
				res => {
					// console.log('TovidioOverlayPublishing getUpdate() response:', res.data);
					this.setUpdate(res.data);
				},
				err => {
					console.error(err);
					this.timeout = setTimeout(this.getUpdate, 5000);
				}
			);
		},

		newVideo() {
			if (!this.isSafeToLeave) return;
			this.$emit('reset');
		}
	},

	created() {

		axios.put('/api/enqueue')
		.then(
			res => {
				console.log('Job enqueued?', res.data);
				this.getUpdate();
			},
			err => console.error('Job failed to enqueue', err)
		);

		// window.upd = data => this.setUpdate(data);
	},

	beforeDestroy() {
		if (this.timeout) clearTimeout(this.timeout);
	},
}
</script>

<style scoped>
.dialog{
	margin: auto;
	background: #fff;
	border-radius: 3px;
	border: 1px solid #000;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.6);
	width: 390px;
	padding: 10px 10px 15px;
}

h1 {
	font-size: 20px;
	line-height: 20px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
	margin: 0;
}

h3 {
	font-size: 18px;
	line-height: 23px;
}

.queue-progress-message {		
	border: 1px solid #D5CFC6;
	background: #FFF294;
	padding: 10px 10px 10px 10px;
	border-radius: 5px;
	position: relative;
}

.queue-progress-message.ok{
	border: 1px solid #9A9C9A;
	background: #B3F18A;
}

.queue-progress-message.error {
	border: 1px solid #CE0E0E;
	background: #F0BDBD;
	color: #000;
}

.queue-progress-message > span {
	display: block;
	margin-left: 34px;
}

.dialog p {
	margin: 0 0 20px 0;
}

.queue-progress-message > img {
	width: 24px;
	height: 24px;
	position: absolute;
	top: 7px;
	left: 10px;
}

.queue-progress-message.inprogress > img {
	animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

</style>


