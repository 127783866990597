var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-bg shift-by-two" },
    [
      _c("panel-image", {
        ref: "panelImage",
        on: {
          "file-selected": function($event) {
            _vm.isImageSelected = true
          },
          "file-uploaded": _vm.onImageUploaded
        }
      }),
      _vm._v(" "),
      _c("panel-audio", {
        ref: "panelAudio",
        on: {
          "file-selected": function($event) {
            _vm.isAudioSelected = true
          },
          "file-uploaded": _vm.onAudioUploaded
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "twelve columns connect-section" },
        [
          _c("p", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isTermsAccepted,
                  expression: "isTermsAccepted"
                }
              ],
              attrs: { type: "checkbox", id: _vm.id },
              domProps: {
                checked: Array.isArray(_vm.isTermsAccepted)
                  ? _vm._i(_vm.isTermsAccepted, null) > -1
                  : _vm.isTermsAccepted
              },
              on: {
                change: function($event) {
                  var $$a = _vm.isTermsAccepted,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isTermsAccepted = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isTermsAccepted = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isTermsAccepted = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: _vm.id } }, [
              _vm._v("\n\t\t\t\tI agree to the\n\t\t\t\t"),
              _c(
                "a",
                { attrs: { href: "/privacy/", target: "PrivacyPolicy" } },
                [_vm._v("Privacy Policy")]
              ),
              _vm._v("\n\t\t\t\tand the\n\t\t\t\t"),
              _c(
                "a",
                { attrs: { href: "/terms/", target: "TermsConditions" } },
                [_vm._v("Terms and Conditions")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "span",
            { class: _vm.isYouTubeConnected ? "badge-done" : "badge" },
            [_vm._v("3")]
          ),
          _vm._v(" "),
          !_vm.isYouTubeConnected
            ? [
                _c(
                  "a",
                  {
                    class: _vm.getActionButtonClass,
                    attrs: {
                      title: "Connect to YouTube",
                      href: "#auth",
                      target: "YouTubeAuth"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.onClickConnectYouTube($event)
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\tCONNECT\n\t\t\t")]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-left": "10px" } }, [
                  _vm._v("to")
                ]),
                _vm._v(" "),
                _c("img", {
                  staticStyle: {
                    width: "126px",
                    margin: "10px 12px 0px 12px",
                    position: "relative",
                    top: "9px"
                  },
                  attrs: {
                    src: "/assets/img/yt_logo_rgb_light.png",
                    alt: "YouTube"
                  }
                })
              ]
            : [
                _c(
                  "a",
                  {
                    class: _vm.getActionButtonClass,
                    attrs: {
                      title: "Create video on YouTube",
                      href: "#create"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.onClickCreateVideo($event)
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\tCREATE VIDEO\n\t\t\t")]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-left": "10px" } }, [
                  _vm._v("on")
                ]),
                _vm._v(" "),
                _c("img", {
                  staticStyle: {
                    width: "126px",
                    margin: "10px 12px 0px 12px",
                    position: "relative",
                    top: "9px"
                  },
                  attrs: {
                    src: "/assets/img/yt_logo_rgb_light.png",
                    alt: "YouTube"
                  }
                })
              ],
          _vm._v("\n\t\tor\n\t\t"),
          _c(
            "a",
            {
              class: _vm.getDownloadButtonClass,
              staticStyle: { "margin-left": "10px" },
              attrs: { href: "#download" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onClickRenderDownload($event)
                }
              }
            },
            [_vm._v("\n\t\t\tdownload\n\t\t")]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "TovidioOverlay",
        { attrs: { isVisible: !!_vm.overlayComponent } },
        [
          _c(_vm.overlayComponent, {
            tag: "component",
            on: {
              "publish-video": _vm.onPublishVideo,
              "retry-auth": _vm.onClickConnectYouTube,
              reset: function($event) {
                return _vm.$emit("reset")
              },
              "collect-details": _vm.onCollectDetails
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }